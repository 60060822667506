import MainComponent from '../../components/main';

const MainPage = () => {
  return (
    <div>
      <MainComponent />
    </div>
  );
};

export default MainPage;
