import ContactComponent from '../../components/contact';

const ContactPage = () => {
  return (
    <div>
      <ContactComponent />
    </div>
  );
};

export default ContactPage;
